/*!
 * Theme Name: Default
 * Package : DashLite
 * Author: Softnio
 * Author URI: http://themeforest.net/user/softnio
 * Version	 :	1.0.0
 * Updated	 :	07.23.2020
**/

.list-badge {
    background-color: #eaeffc;
    padding: 4px 7px;
    border-radius: 5px;
    margin-right: 10px;
}

.stepper-text {
        font-size: 48px;
    font-weight: 600;
}